.popup__input {
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border: none;
  padding: 0 0 10px;
  border-bottom: 1px solid var(--color-main-20);
  width: 100%;
  opacity: 1;
}

.popup__input:active,
.popup__input:focus {
  outline-color: var(--color-main-20);
}

.popup__form:not([novalidate]) .popup__input:invalid {
  border-color: var(--color-error);
}

.popup__input::-webkit-input-placeholder {
  color: var(--color-placeholder);
  opacity: 1;
}

.popup__input::-moz-placeholder {
  color: var(--color-placeholder);
  opacity: 1;
}

.popup__input:-ms-input-placeholder {
  color: var(--color-placeholder);
  opacity: 1;
}

.popup__input::-ms-input-placeholder {
  color: var(--color-placeholder);
  opacity: 1;
}

.popup__input::placeholder {
  color: var(--color-placeholder);
  opacity: 1;
}