.footer__copyright {
  color: var(--color-gray);
  max-width: 880px;
  margin: 0 auto;
  padding: 0 19px 36px;
}

@media (min-width: 880px) {
  .footer__copyright {
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 60px;
  }
}
