.profile__avatar {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;

  margin-bottom: 26px;
  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 880px) {
  .profile__avatar {
    margin-bottom: 0;
  }
}