.header {
  max-width: 880px;
  width: 100%;

  box-sizing: border-box;
  border-bottom: 1px solid var(--color-gray-70);
  padding: 28px 0 26px 27px;

  flex-shrink: 0;
}

@media (min-width: 880px) {
  .header {
    padding: 45px 0 35px 0;
  }
}